<template>
    <front-layout>
        <div class="flex-wrapper-full-background d-flex  fill-height" style="width: 100%">
            <v-card class="mx-auto my-auto pa-5" max-width="900" min-width="500" raised>
                <v-card-title>{{ $t('auth.sign_up') }}</v-card-title>
                <v-container>
                    <v-form ref="form">
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.email" :label="$t('auth.email')" v-model="email"
                                              :rules="rules_email"
                                              type="email"
                                              @input="btn_active()"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.password"
                                              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                              :type="show_password ? 'text' : 'password'"
                                              :label="$t('auth.password')" v-model="password"
                                              :rules="[rules_password.required, rules_password.min]"
                                              error
                                              @input="btn_active()"
                                              @click:append="show_password = !show_password"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.password_confirmation"
                                              :append-icon="show_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                              :type="show_password_confirmation ? 'text' : 'password'"
                                              :label="$t('auth.password')"
                                              v-model="password_confirmation"
                                              :rules="rules_password_confirm"
                                              error
                                              @input="btn_active()"
                                              @click:append="show_password_confirmation = !show_password_confirmation"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="handleSignUp" :disabled="!btn_disabled">{{ $t('auth.sign_up') }}</v-btn>
                            </v-col>
                            <!--     <v-col>
                                     <login-with-github />
                                 </v-col>
                                 <v-col>
                                     <login-with-google />
                                 </v-col>-->
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";

export default {
    name: "Login",
    components: {FrontLayout},
    data: function () {
        return {
            rules_email: [
                value => !!value || 'Required.',
                value => (value && value.length >= 6) || 'Min 6 characters',
                value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            ],
            rules_password: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 6 || 'Min 6 characters',
            },
            rules_password_confirm: [
                value => this.password === value || 'Password mismatch.',
            ],
            email: '',
            password: '',
            password_confirmation: '',
            errors: {},
            user: [],
            show_password: false,
            show_password_confirmation: false,
            btn_disabled: false
        }
    },
    methods: {
        async handleSignUp() {
            const {email, password, password_confirmation} = this;
            if (this.validate()) {
                await this.$auth.register({
                    data: {email, password, password_confirmation},
                    success() {
                        this.$auth.ready();
                    },
                    staySignedIn: true,
                    redirect: {name: 'dashboard.index'},

                }).catch(e => this.errors = e.response.data.errors)
            }
        },
        validate() {
            return this.$refs.form.validate()
        },
        btn_active() {
            this.btn_disabled = this.validate()
        }
    }
}
</script>

<style scoped lang="scss">

</style>
